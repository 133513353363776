import { useCallback } from 'react';

export interface WindowMessage {
  type: string;
  data?: unknown;
}

export const usePostWindowMessage = <Payload = WindowMessage>() => {
  return useCallback((data: Payload) => {
    window?.top?.postMessage(JSON.stringify(data), '*');
  }, []);
};
