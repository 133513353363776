import { CloudflareTurnslite } from 'providers/cloudflare/CloudflareTurnslite';
import { FC, useMemo } from 'react';
import { GoogleCaptcha } from './providers/google/GoogleCaptcha';

export const App: FC = () => {
  const Provider = useMemo(() => {
    const params = new URLSearchParams(window.location.search);
    const isCloudflare = !!params.get('turnstile');

    return isCloudflare ? CloudflareTurnslite : GoogleCaptcha;
  }, []);

  return <Provider />;
};
