import { useCallback, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import styled from 'styled-components';

HTMLCollection.prototype.find = Array.prototype.find;

export function GoogleCaptcha() {
  const [siteKey, setSiteKey] = useState('');
  const [top, setTopValue] = useState('0');
  const [recaptchaWindow, setRecaptchaWindow] = useState();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    const top = params.get('top');

    if (token) {
      setSiteKey(token);
    }

    if (top) {
      setTopValue(top);
    }
  }, []);

  const callParent = data => {
    window.top.postMessage(JSON.stringify(data), '*');
  };

  const onCaptchaChange = useCallback(token => {
    if (token) {
      callParent({
        type: 'success',
        data: token,
      });
    }
  }, []);

  const onExpired = useCallback(
    e => {
      setRecaptchaWindow(undefined);
      callParent({
        type: 'expired',
        data: e,
      });
    },
    [setRecaptchaWindow]
  );

  const onError = useCallback(e => {
    callParent({
      type: 'error',
      data: e,
    });
  }, []);

  const handleRecaptchaWindow = useCallback(() => {
    const recaptchaWindow = document
      .getElementsByTagName('iframe')
      .find(x => x.src.includes('google.com/recaptcha/api2/bframe'));
    if (
      recaptchaWindow &&
      recaptchaWindow.parentNode &&
      recaptchaWindow.parentNode.parentNode
    ) {
      setRecaptchaWindow(recaptchaWindow.parentNode.parentNode);
    }
  }, [setRecaptchaWindow]);

  useEffect(() => {
    let interval = setInterval(() => {
      if (recaptchaWindow) {
        clearInterval(interval);
        interval = null;
        startObserver(recaptchaWindow);
      } else {
        handleRecaptchaWindow();
      }
    }, 100);

    let observer = null;

    const startObserver = recaptchaWindow => {
      observer = new MutationObserver(x => {
        if (+recaptchaWindow.style.opacity === 0) {
          callParent({
            type: 'popup_closed',
          });
        } else if (+recaptchaWindow.style.opacity > 0) {
          callParent({
            type: 'popup_opened',
          });
        }
      });
      observer.observe(recaptchaWindow, {
        attributes: true,
        attributeFilter: ['style'],
      });
    };

    return () => {
      // clean up
      interval && clearInterval(interval);
      observer && observer.disconnect();
    };
  }, [recaptchaWindow, handleRecaptchaWindow]);

  return (
    <Captcha top={top}>
      <ReCAPTCHA
        sitekey={siteKey}
        onChange={onCaptchaChange}
        onExpired={onExpired}
        onErrored={onError}
      />
    </Captcha>
  );
}

const Captcha = styled.div`
  div > div > div[style] {
    position: absolute;
    top: ${({ top }) => top}px;
    left: 50%;
    transform: translateX(-50%);
  }
`;
