import { useLoadScript } from 'hooks/useLoadScript';
import { usePostWindowMessage } from 'hooks/usePostWindowMessage';
import { FC, useEffect, useMemo, useRef } from 'react';
import { ETurnsliteMessage, TurnsliteWindowEvent } from './turnsliteEvents';

const TURNSLITE_CONTAINER_ID = 'turnslite-container';

export const CloudflareTurnslite: FC = () => {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const turnsliteInstancesId = useRef<string | undefined>();

  const postMessage = usePostWindowMessage<TurnsliteWindowEvent>();

  const settings = useMemo((): Turnstile.RenderParameters => {
    const params = new URLSearchParams(window.location.search);

    return {
      sitekey: params.get('sitekey') ?? '',
      theme: (params.get('theme') as Turnstile.Theme) ?? 'light',
    };
  }, []);

  useMemo(() => {
    window.onloadTurnstileCallback = () => {
      const container = containerRef.current;

      if (!container) return;

      turnsliteInstancesId.current = turnstile.render(container, {
        ...settings,
        callback: token => {
          postMessage({
            type: ETurnsliteMessage.RECEIVE_TOKEN,
            data: {
              token,
            },
          });
        },
        'expired-callback': () => {
          postMessage({
            type: ETurnsliteMessage.EXPIRED,
          });
        },
        'error-callback': (err = undefined) => {
          postMessage({
            type: ETurnsliteMessage.ERROR,
            data: { error: err },
          });
        },
      });
    };
  }, [postMessage, settings]);

  useEffect(
    () => () => {
      turnsliteInstancesId.current &&
        //@ts-ignore
        turnstile.remove(turnsliteInstancesId.current);
    },
    []
  );

  useLoadScript(
    'https://challenges.cloudflare.com/turnstile/v0/api.js?onload=onloadTurnstileCallback'
  );

  return (
    <div
      ref={containerRef}
      id={TURNSLITE_CONTAINER_ID}
      className="captcha-wrapper"
    />
  );
};
