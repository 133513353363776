import { useEffect, useMemo, useRef } from 'react';

export const useLoadScript = (src: string) => {
  const promiseRef = useRef<{
    promise?: Promise<void>;
    resolve?: () => void;
    reject?: (err: unknown) => void;
  }>({});

  useMemo(() => {
    promiseRef.current.promise = new Promise((res, rej) => {
      promiseRef.current.resolve = res;
      promiseRef.current.reject = rej;
    });
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.onload = () => promiseRef.current.resolve?.();
    script.onerror = err => promiseRef.current.reject?.(err);
    script.src = src;
    document.body.appendChild(script);
  }, [src]);

  return promiseRef.current.promise;
};
