export enum ETurnsliteMessage {
  RECEIVE_TOKEN = 'receive-token',
  EXPIRED = 'expired',
  ERROR = 'error',
}

export interface CreateTurnsliteWindowEvent<
  Type extends ETurnsliteMessage,
  Payload extends unknown
> {
  type: Type;
  data: Payload;
}

export type TurnsliteWindowEvent =
  | CreateTurnsliteWindowEvent<
      ETurnsliteMessage.RECEIVE_TOKEN,
      {
        token: string;
      }
    >
  | CreateTurnsliteWindowEvent<
      ETurnsliteMessage.ERROR,
      {
        error: unknown;
      }
    >
  | { type: ETurnsliteMessage.EXPIRED };
